<template>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <CustomerNavbar></CustomerNavbar>
            </div>
            <div class="col-md-9">
                <div class="panel panel-default">
                    <div class="panel-heading dash-head">My Dashboard</div>
                    <div class="panel-body">
                        <div class="acc-info">
                            <div class="acc-info-head">
                                <h4>ACCOUNT INFORMATION</h4>
                            </div>
                            <div class="row">
                                <div class="col-md-6 acc-left">
                                    <div class="acc-title">
                                        <p>Contact Information</p>
                                    </div>
                                    <table>
                                        <tr>
                                            <td>Name :</td>
                                            <td> {{customer.customer.name}}</td>
                                        </tr>
                                        <tr>
                                            <td>Email :</td>
                                            <td> {{customer.customer.email}}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone :</td>
                                            <td> {{customer.customer.phone}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-6 acc-right">
                                    <div class="acc-title">
                                        <p>Newsletters</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row address">
                                <div class="col-md-12">
                                    <br>
                                    <p style="border-bottom: 1px dotted; padding-bottom: 5px">Address Book</p>
                                </div>
                                <div class="col-md-6">
                                    <strong>DEFAULT BILLING ADDRESS</strong>
                                </div>
                                <div class="col-md-6">
                                    <strong>DEFAULT SHIPPING ADDRESS</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import CustomerNavbar from './CustomerNavbar'
    export default {
        components: {
            CustomerNavbar
        },
        computed: {
            ...mapGetters([
                "me","customer","user"
            ]),
        },
      mounted() {
        this.$store.dispatch("getMe");
        this.$store.dispatch("getcustomerSummery");
      },

    }

</script>
<style>
    .dash-head {
        text-align: left;
        background: #000;
    }
    .acc-info{
        text-align: left;
    }
    .acc-info-head h4 {
        font-size: 12px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 12px;
        color: #e65e06;
        margin-bottom: 10px;
    }
    .acc-title p:after {
        position: absolute;
        content: "";
        height: 1px;
        width: 80%;
        left: 7px;
        top: 30px;
        border-bottom: 1px dotted;
    }
    .acc-left table tr td {
        padding: 10px 0px;
    }

</style>